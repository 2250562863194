module.exports = [{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":590,"linkImagesToOriginal":true,"showCaptions":false,"markdownCaptions":false,"sizeByPixelDensity":false,"backgroundColor":"white","quality":50,"withWebp":false,"tracedSVG":false,"loading":"lazy","disableBgImageOnAlpha":false,"disableBgImage":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"The Well Child","short_name":"The Well Child","start_url":"/","background_color":"#ffffff","theme_color":"#39CDE0","display":"minimal-ui","icon":"content/assets/doctor-2411135_1280.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"91e13bdca519fae2b0b4331ef931deca"},
    },{
      plugin: require('../node_modules/@sentry/gatsby/gatsby-browser.js'),
      options: {"plugins":[],"dsn":"https://cfbe7141660b4338816487a18d43b8c4@o265823.ingest.sentry.io/5441881","sampleRate":0.7},
    },{
      plugin: require('../node_modules/gatsby-plugin-typography/gatsby-browser.js'),
      options: {"plugins":[],"pathToConfigModule":"src/utils/typography"},
    },{
      plugin: require('../node_modules/gatsby-plugin-firebase/gatsby-browser.js'),
      options: {"plugins":[],"features":{"analytics":true},"credentials":{"apiKey":"AIzaSyArgSiHM__9Z5RAA0dU-gy4eWOiuv31YzE","authDomain":"naomi-nadiv.firebaseapp.com","databaseURL":"https://naomi-nadiv.firebaseio.com","projectId":"naomi-nadiv","storageBucket":"naomi-nadiv.appspot.com","messagingSenderId":"393546211212","appId":"1:393546211212:web:12ee3e81cc1b4b3ef280a9","measurementId":"G-PRCTEDSQL2"}},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
